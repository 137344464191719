<template>
  <div class="view--registration">
    <v-card>
      <v-toolbar class="justify-space-between" color="primary" dark flat>
        <v-toolbar-title>Регистрация</v-toolbar-title>
      </v-toolbar>
      <sign-up-form />
    </v-card>
  </div>
</template>

<script>
import SignUpForm from '@/components/forms/SignUpForm.vue';
export default {
  data: () => ({}),
  components: { SignUpForm },
  mounted() {
    this.$store.dispatch('startIdleTracker');
  },
};
</script>
