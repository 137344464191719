<template>
  <v-menu
    ref="date-picker-ref"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
    top
    nudge-right="10"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="dateFormatted"
        :label="label"
        :rules="rule_date"
        ref="textfield"
        :disabled="disabled"
        clearable
        @blur="setDatePicker()"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="dateISO"
      @input="menu = false"
      ref="picker"
      :max="today"
      :disabled="disabled"
      no-title
    ></v-date-picker>
  </v-menu>
</template>

<script>
import Inputmask from 'inputmask/dist/inputmask/inputmask.date.extensions';

export default {
  name: 'timestamp-date-picker',

  props: {
    value: { default: undefined },
    label: {
      type: String,
    },
    rules: {
      type: Array,
      default: function() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      menu: false,
      rule_date: this.rules.concat([
        () => this.isDateFormatCorrect() || 'Некорректный формат даты',
      ]),

      dateFormatted: null, // модель для текстового поля
      dateISO: null, // модель для датапикера

      today: null,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.dateISO = val
          ? new Date(val).toISOString().substr(0, 10)
          : undefined;
      },
    },
    dateISO(val) {
      this.dateFormatted = this.formatDate(val);
      if (val) {
        let date = new Date(val);
        this.$emit('input', date.valueOf());
      } else {
        this.$emit('input', null);
      }
    },
    menu(val) {
      val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
  },

  methods: {
    formatDate(dateString) {
      if (!dateString) return null;
      let date = new Date(dateString);
      let year = String(date.getFullYear()),
        month = String(date.getMonth() + 1),
        day = String(date.getDate());
      return `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${year}`;
    },
    isDateFormatCorrect() {
      return this.dateFormatted && this.dateFormatted.length > 0
        ? Boolean(Date.parse(this.parseDate(this.dateFormatted)))
        : true;
    },
    setDatePicker() {
      let parsedDate = this.parseDate(this.dateFormatted);
      this.dateISO = parsedDate;
    },
    parseDate(date) {
      if (!date) return undefined;
      const [day, month, year] = date.split('.');
      if (
        day &&
        day.length > 0 &&
        month &&
        month.length > 0 &&
        year &&
        year.length > 0
      ) {
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      }
      return undefined;
    },
  },
  mounted() {
    Inputmask({
      mask: this.$globals.dateMask,
      showMaskOnHover: false,
      placeholder: 'дд/мм/гггг',
    }).mask(this.$refs.textfield.$refs.input);

    let today = new Date();
    this.today = today.toISOString();
  },
};
</script>
