<template>
  <div class="form--registration">
    <v-card-text>
      <v-row>
        <v-col class="mx-auto max-width-600" color="accent" align="right">
          <v-btn class="mr-2" @click="reset" text>
            Очистить форму
          </v-btn>
        </v-col>
      </v-row>
      <v-form ref="form" :disabled="phoneVerificationStep">
        <v-row>
          <v-col class="mx-auto max-width-600">
            <v-alert
              v-show="signupError"
              transition="scale-transition"
              border="left"
              outlined
              type="error"
            >
              {{ signupErrorMessage }}
            </v-alert>
            <v-text-field
              v-model="formData.phone"
              :rules="$globals.phoneRules"
              ref="phone"
              class="max-width-600"
              label="Телефон"
              name="phone"
              prepend-icon="mdi-phone"
              type="tel"
              required
            />
            <v-text-field
              v-model="formData['e-mail']"
              :rules="$globals.emailRules"
              class="max-width-600"
              label="E-mail"
              name="email"
              prepend-icon="mdi-at"
              type="email"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mx-auto max-width-600">
            <v-text-field
              v-model="formData.lname"
              :rules="
                [].concat(
                  $globals.requiredRule,
                  $globals.isWordRule,
                  $globals.lengthLimitRule
                )
              "
              class="max-width-600"
              label="Фамилия"
              name="lname"
              prepend-icon=""
              required
            />
            <v-text-field
              v-model="formData.fname"
              :rules="
                [].concat(
                  $globals.requiredRule,
                  $globals.isWordRule,
                  $globals.lengthLimitRule
                )
              "
              class="max-width-600"
              label="Имя"
              name="fname"
              prepend-icon=""
            />
            <v-text-field
              v-model="formData.father_name"
              :rules="[].concat($globals.isWordRule, $globals.lengthLimitRule)"
              class="max-width-600"
              label="Отчество"
              name="father_name"
              prepend-icon=""
            />
            <div v-if="promo == ''">
              <v-text-field
                v-model="formData.referer_promo"
                :rules="
                  [].concat($globals.isPromoRule, $globals.lengthLimitRule)
                "
                class="max-width-600"
                label="Промокод пригласившего"
                name="referer_promo"
                prepend-icon=""
              />
            </div>
            <div v-if="promo != ''">
              <v-text-field
                v-model="formData.referer_promo"
                :rules="
                  [].concat($globals.isPromoRule, $globals.lengthLimitRule)
                "
                class="max-width-600"
                label="Промокод пригласившего"
                name="referer_promo"
                prepend-icon=""
                disabled
              />
            </div>
            <div class="max-width-600">
              <timestamp-date-picker
                v-model="formData.birthday"
                label="Дата рождения"
              />
            </div>
            <v-checkbox
              v-model="formData.data_processing_agreement"
              :rules="$globals.requiredRule"
              auto-complete="one-time-code"
              required
            >
              <template v-slot:label>
                <div>
                  <a
                    href="https://k7.su/consentpage/1"
                    @click.stop
                    target="_blank"
                  >
                    Соглашаюсь на обработку персональных данных
                  </a>
                </div>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="formData.service_agreement"
              :rules="$globals.requiredRule"
              autocomplete="one-time-code"
              required
            >
              <template v-slot:label>
                <div>
                  Соглашаюсь с
                  <a
                    href="https://k7.su/termspage/1"
                    @click.stop
                    target="_blank"
                  >
                    правилами
                  </a>
                  использования сервиса
                </div>
              </template>
            </v-checkbox>
            <v-text-field
              v-model="formData.are_you_human"
              label="are_you_human"
              name="are_you_human"
              style="display: none;"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col class="mx-auto max-width-600">
          <v-card v-if="phoneVerificationStep" max-width="600">
            <v-card-title primary-title>
              Вам отправлен код подтверждения
            </v-card-title>
            <v-card-text>
              <v-alert
                v-show="verificationError"
                transition="scale-transition"
                border="left"
                outlined
                type="error"
              >
                {{ verificationErrorMessage }}
              </v-alert>
            </v-card-text>
            <v-card-actions class="mx-2">
              <v-text-field
                v-model="verificationCode"
                class="max-width-600"
                label="Введите код подтверждения"
                name="verificationCode"
              />
              <v-btn
                @click="verify"
                :loading="verificationCountdown > 0"
                text
                color="accent"
              >
                ПОДТВЕРДИТЬ
              </v-btn>
            </v-card-actions>
            <v-card-text>
              <span v-if="resendCountdown > 0">
                Повторная отправка через {{ resendCountdown }} секунд
              </span>
              <span v-else>
                <span
                  @click="resend"
                  class="text-decoration-underline accent--text cursor-pointer"
                >
                  Не получили одноразовый код?
                </span>
              </span>
            </v-card-text>
          </v-card>
          <div align="center" v-else>
            <v-btn
              @click="signup"
              :disabled="blocked"
              class="mr-2"
              color="success"
              outlined
            >
              Зарегистрироваться
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <div
        class="justify-center align-end"
        style="position: absolute; bottom: 0; right: 20px;"
      >
        <v-alert
          transition="scroll-x-reverse-transition"
          v-model="promocode_validation_dialog"
          color="red"
          dismissible
          type="error"
        >
          Введённый промокод не найден
        </v-alert>
      </div>
    </v-card-text>
  </div>
</template>

<script>
import qs from 'qs';

import TimestampDatePicker from '@/components/TimestampDatePicker.vue';
import Inputmask from 'inputmask/dist/inputmask/inputmask.date.extensions';

function getPromo() {
  let queryString = window.location.search;
  let urlParams = new URLSearchParams(queryString);
  let promo = urlParams.get('promocode');
  console.log(promo);
  if (!promo) {
    promo = '';
  }
  return promo;
}

const promo = getPromo();
const resendCooldown = 10;
const verificationCooldown = 3;

let promocode_validation_dialog = false;
const defaultFormData = {
  phone: '',
  'e-mail': '',
  fname: '',
  lname: '',
  father_name: '',
  referer_promo: promo,
  birthday: null,
  data_processing_agreement: false,
  service_agreement: false,
  are_you_human: undefined,
};

export default {
  components: {
    TimestampDatePicker,
  },
  data: () => ({
    promocode_validation_dialog: promocode_validation_dialog,
    signupError: false,
    signupErrorMessage: 'Произошла ошибка регистрации, повторите попытку',
    verificationError: false,
    verificationErrorMessage: 'Произошла ошибка верификации, повторите попытку',

    phoneVerificationStep: false,
    resendCountdown: resendCooldown,
    verificationCountdown: 0,
    resendInterval: null,
    verificationInterval: null,
    blocked: false,

    promo,
    formData: {},

    verificationCode: null,
    customerId: null,
  }),
  methods: {
    reset() {
      this.formData = { ...defaultFormData };
      this.customerId = null;
      this.phoneVerificationStep = false;
      this.signupError = false;
      this.verificationError = false;
    },
    signup() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.blocked = true;

      let payload = { data: { new: { ...this.formData } } };
      payload.data.new.birthday = payload.data.new.birthday / 1000;

      this.$http({
        method: 'post',
        url: '/udata/users/signup/.json',
        data: qs.stringify(payload),
      })
        .then((response) => {
          let data = response.data;
          if (data.result) {
            this.signupError = false;
            this.customerId = data.result.customer_id;
            this.phoneVerificationStep = true;
            this.startResendCountdown();
          } else {
            this.signupError = true;
          }
          if (data.error) {
            this.signupError = true;
            this.signupErrorMessage = data.error;
          } else {
            this.signupErrorMessage =
              'Произошла ошибка  регистрации, повторите попытку';
          }
        })
        .catch((err) => {
          console.error(err);
          this.signupError = true;
          this.signupErrorMessage =
            'Произошла ошибка  регистрации, повторите попытку';
        })
        .finally(() => {
          this.blocked = false;
        });
    },
    verify() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.startVerificationCountdown();

      this.$http({
        method: 'get',
        url: `/udata/users/check_user_verification_code/${this.customerId}/${this.verificationCode}/.json`,
      })
        .then((response) => {
          let data = response.data;
          if (data.error) {
            this.verificationError = true;
            this.verificationErrorMessage = data.error;
          }
          if (data.result == 'success') {
            this.responseMessage = data.message;
            this.$router.push({ name: 'signup-success' });
          }
        })
        .catch((err) => {
          console.error(err);
          this.verificationError = true;
          this.verificationErrorMessage =
            'Произошла ошибка верификации, повторите попытку';
        });
    },
    resend() {
      this.startResendCountdown();

      this.$http({
        method: 'get',
        url: `/udata/users/send_user_verification_code/${this.customerId}/.json`,
      })
        .then((response) => {
          let data = response.data;
          if (data.error) {
            this.verificationError = true;
            this.verificationErrorMessage = data.error;
          }
        })
        .catch((err) => {
          console.error(err);
          this.verificationError = true;
          this.verificationErrorMessage =
            'Произошла ошибка при отправке кода, повторите попытку';
        });
    },
    startResendCountdown() {
      this.startCountdown(
        'resendCountdown',
        resendCooldown,
        this.resendInterval
      );
    },
    startVerificationCountdown() {
      this.startCountdown(
        'verificationCountdown',
        verificationCooldown,
        this.verificationInterval
      );
    },
    startCountdown(countdown, cooldown, interval) {
      this[countdown] = cooldown;
      interval = setInterval(() => {
        this[countdown]--;
        if (this[countdown] === 0) clearInterval(interval);
      }, 1000);
    },
  },
  created() {
    this.reset();
  },
  mounted() {
    Inputmask({
      mask: this.$globals.phoneMask,
      showMaskOnHover: false,
      autoUnmask: true,
    }).mask(this.$refs.phone.$refs.input);
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/styles.sass'

.max-width-600
  max-width: 600px

.cursor-pointer
  cursor: pointer
</style>
